import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import { FaInstagram } from "react-icons/all";
import { instagram, instagramLink, titleLarge, titleSmall } from './hero.module.scss';

const Hero = ({ large }) => {
    var image

    if (large) {
        image = <StaticImage
            style={{ gridArea: "1/1" }}
            layout="fullWidth"
            // You can optionally force an aspect ratio for the generated image
            aspectRatio={3 / 1}
            // This is a presentational image, so the alt should be an empty string
            alt=""
            src={
                "../images/hero_tesla4.jpg"
            }
            formats={["auto", "webp", "avif"]}
            loading="eager"
            objectPosition={"0%"}
            objectFit={"cover"}
            placeho="blurred"
        />
    } else {
        image = <StaticImage
            style={{ gridArea: "1/1", maxHeight: "100px" }}
            layout="fullWidth"
            // You can optionally force an aspect ratio for the generated image
            aspectRatio={3 / 1}
            // This is a presentational image, so the alt should be an empty string
            alt=""
            src={
                "../images/hero_tesla4.jpg"
            }
            formats={["auto", "webp", "avif"]}
            loading="eager"
            objectPosition={"0% 40%"}
            objectFit={"cover"}
            placeho="blurred"
        />
    }

    return (
        <div style={{ display: "grid" }}>
            {/* You can use a GatsbyImage component if the image is dynamic */}
            {image}
            <div
                style={{
                    // By using the same grid area for both, they are stacked on top of each other
                    gridArea: "1/1",
                    position: "relative"
                }}
            >
                {/* Any content here will be centered in the component */}
                <Link to="/"><h1 className={large ? titleLarge : titleSmall}>Sagotassens {large ? <br /> : ""} Kennel</h1></Link>
                <Link to={"https://www.instagram.com/sagotassenskennel/"} className={instagramLink}>
                    <FaInstagram className={instagram} />
                </Link>
            </div>
        </div>
    )
}

export default Hero